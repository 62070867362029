.col-header {
  border-right: 2px solid rgb(83, 90, 208);
  margin: 0;
  padding: 0 1em;
  width: 165px;
  padding-right: 20px !important;
  /* margin-right: 20px; */
  background: linear-gradient(90deg, transparent, #535ad02b);
}

.col-value {
  min-width: 200px;
}

.col-value input {
  display: block;
  border: none !important;
  border-bottom: 1px solid #0000ff0a !important;
  padding: 8px 10px;
  font-size: 13px;
  width: 100%;
}

.col-header div {
  margin-top: 5px;
  padding: 3px;
}

.company-name {
  font-size: 1.5em;
  margin-bottom: 0;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 10px;
}

.cnpj {
  font-size: 1em;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.field-parceiro {
  background: var(--my-secondary);
  color: white;
}

.field-parceiro::placeholder {
  color: #ffffff6b;
}

.button-add {
  background: #bcbcbc;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: none;
  width: 50px;
  height: 50px;
  margin-left: 1em;
}

.button-add:hover {
  background: red;
}

.col-button {
  width: 100px;
  background: #eeeeee;
  text-align: center;
  padding: 0;
  padding-top: 190px;
  padding-right: 22px !important;
  height: 470px;
}

.parceiro-item {
  border-left: 1px solid #0000ff33;
}

.btn-remove {
  float: right;
  padding: 2px 9px !important;
  margin-top: 3px;
  margin-right: 4px;
  background: none;
  border: none;
  color: white;
}

.header-input {
  max-width: 100px;
  float: left;
}

.field-parceiro-input {
  display: inline-block !important;
  width: 160px !important;
}

.date-item {
  width: 200px !important;
}

.input_porcentage {
  width: 172px !important;
}

.porcentage-text {
  background: white;
  border: none;
  height: 35px !important;
  width: 29px;
}

.input-textarea {
  width: 100%;
  height: 85px;
  border: none !important;
  font-size: 12px;
}
