.chart-root {
}
.chart-btn {
  background: var(--my-secondary);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  position: fixed;
  bottom: 80px;
  right: 116px;
}

.chart-icon {
  color: white;
  margin-top: 10px;
}

.chart-btn:hover {
  background: red;
  cursor: pointer;
}

.chart-hide2 {
  background: var(--my-secondary);
  opacity: 0.3;
}
