.table-root {
  max-width: 45%;
  font-size: 12px;
}

.table-root caption {
  font-weight: 400;
  color: black;
}

.table-divisor {
  height: inherit;
  width: 0px;
  border-right: 1px dashed #00000033;
  margin-right: 20px;
  padding-left: 20px;
}

table > thead > tr > th.col-data {
  width: 120px;
}

table > thead > tr > th.col-porcent {
  width: 50px !important;
  text-align: center !important;
}

table > tbody > tr > td.col-row-porcent {
  width: 50px !important;
  text-align: center !important;
}

table > tbody > tr > td.col-row-data {
  border-left: 2px dashed#d4d6fd;
}

.row-item.highlight {
  background: #d4d6fd !important;
}

.row-item.highlight.total {
  background: #181919 !important;
  color: white;
}

.row-item td {
  padding: 0;
}

.table-sm td,
.table-sm th {
  padding: 2px 10px;
  font-weight: 600;
}

.row-item.highlight > th {
  font-weight: bold;
}

.col-porcent {
  text-align: center;
}

.col-date-item {
  float: left;
}

.col-porcent-item {
  float: right;
}

.table-divider {
  border-top: 1px dashed #0000003d;
  margin-top: 22px;
  padding-top: 22px;
}

h3 {
  font-weight: 700;
  margin-bottom: 2px;
  margin-top: 10px;
  font-size: 1em;
  background: var(--my-secondary);
  color: white;
  display: inline-block;
  padding: 8px;
}

.minus-value {
  color: red !important;
  font-weight: 700;
  font-size: 10px;
}

.plus-value {
  font-size: 10px;
}

.col-porcent-item {
  font-size: 10px;
}

.table .thead-dark th {
  color: #fff;
  background-color: var(--my-secondary);
  border-color: var(--my-secondary);
  width: 100px;
}
