.section-item {
  display: inline-block;
}

/* .block-line{
    border-bottom: 1px dashed #4242442e;
    padding: 7px;
} */

.label-instituicao {
  width: 100%;
}

.label-instituicao input {
  padding: 6px;
  margin: 0;
  border: 1px solid #0000001c !important;
  border-right: none !important;
  background: linear-gradient(90deg, white 70%, transparent);
  text-align: right;
  font-weight: 100;
  text-transform: uppercase;
  color: var(--my-secondary);
}

.input-money {
  width: 100%;
  max-width: 150px;
}

.label-obs input {
  margin: 0;
  border: 1px solid #0000001c !important;
  border-left: none !important;
  background: linear-gradient(90deg, #f8f8f8 70%, transparent);
  text-align: right;
  font-weight: 100;
  width: 100%;
  font-size: 12px;
  padding: 9px;
  text-transform: uppercase;
}

.fundo-item:hover {
  background: #e1e1e150;
}

.divisor-vertical {
  border-right: 2px dashed #5e64c8;
  height: 100%;
  text-align: center;
  width: 1px;
  margin: 0 auto;
}

.total-secondLine {
  position: relative;
  top: -7px;
  font-size: 1.6em;
}

.btn-add {
  margin-left: 10px;
  padding: 0;
  position: relative;
  top: -3px;
  border: none;
}

.btn:hover {
  background: none;
}
.fundo-item {
  padding: 0;
}

.total-txt-sub {
  font-size: 1em;
  position: relative;
  top: 6px;
}

.total-secondLine > span {
  font-size: 1em;
}
