@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,500,700,800|Asap+Condensed:400,500&display=swap');

.btn-outline-primary {
  color: var(--my-primary);
  border: 2px solid var(--my-primary);
  border-radius: 7px;
  padding: 8px 13px;
}
.btn-outline-primary:hover:enabled {
  color: white;
  background: var(--my-primary) !important;
  border-color: var(--my-primary) !important;
}
.btn-outline-primary:disabled {
  color: #505385;
  border: 2px solid #505385;
  border-radius: 7px;
  padding: 8px 13px;
}

.btn-outline-secondary {
  color: var(--my-secondary);
  border: 2px solid var(--my-secondary);
  border-radius: 7px;
  padding: 8px 13px;
}
.btn-outline-secondary:hover {
  color: white;
  background: var(--my-secondary);
  border-color: var(--my-secondary);
}

.btn-outline-secondary:disabled {
  border-color: rgba(0, 0, 0, 0.2);
}

body {
  margin: 0;
  font-family: 'Open Sans';
}

.content {
  width: calc(100% - 100px);
  background: #f5f5f5;
  /* margin-left: calc(100px); */
}

a:hover {
  text-decoration: none;
}

.bodyContent {
  padding: 1.5em;
  margin-top: 70px;
  padding-bottom: 150px;
}

.label {
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
  color: var(--my-primary);
  margin-right: 1em;
}

.form-control:focus {
  border-color: #e2083000;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 3px #30358ba8;
}

.form-group {
  margin-top: 1em;
  margin-left: 0.5em;
}

.divisor {
  border-bottom: 1px dashed #30358b38;
  clear: both;
  width: 100%;
  margin: 1em 0;
}

.number_pos {
  border: none;
  font-weight: 800;
  color: #2f358c;
  background: none;
  padding: 0;
  margin-top: 10px;
}

.footer {
  bottom: 0;
  width: calc(100% - 100px);
  height: 60px;
  background-color: white;
  position: fixed;
  left: 0;
  z-index: 97;
  padding-top: 25px;
  margin-left: 100px;
}

.progress-bar {
  background-color: var(--my-secondary);
}

.progress-dados {
  background-color: #7853d0;
}

.progress-ativo {
  background-color: blue;
}

.progress-passivo {
  background-color: green;
}

.progress-mutacao {
  background-color: orange;
}

.footer-txt {
  font-size: 10px;
  margin-top: 1%;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}

.progress {
  display: flex;
  height: 5px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  margin-top: 8px;
}

.error_input {
  border-bottom: 4px solid red !important;
}

.first_input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.RSPBprogressBar .RSPBprogression {
  background: #525ad0 !important;
}

/* TOAST */
.toast-background {
  background: #525ad0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 10px;
}

.toast-red {
  background: red !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 10px;
}

.toast-red .toast-body {
  background: red;
}

.toast-body {
  background: #525ad0;
  margin: 0;
  color: white;
  font-family: 'Open Sans';
  font-size: 1em;
  font-weight: 100;
}

.toast-progress {
  background: green;
}

input,
textarea {
  outline: none;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
}

.swal-title {
  font-family: 'Open Sans';
  text-transform: uppercase;
  font-weight: 600;
  color: var(--my-secondary);
}
.field-parceiro-input {
  width: 160px !important;
}

.btn-salvar:hover {
  background: black !important;
  color: white !important;
  border-color: black !important;
  cursor: pointer;
}
.btn-reset:hover {
  background: red !important;
  color: white !important;
  border-color: red !important;
  cursor: pointer;
}
