.pageTitle {
  background: white;
  padding: 20px 20px 0;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 1.35em;
  height: 69px;
  color: var(--my-secondary);
  position: fixed;
  width: 100%;
  z-index: 99;
}

.titlePage {
  margin-left: 10px;
}

.iconPage {
  font-size: 30px;
  position: relative;
  top: -9px;
}

.btnSave {
  margin-right: 100px;
  font-weight: 600;
  padding: 5px;
  height: 35px;
  max-width: 160px;
}

.btnSave:hover {
  background: var(--my-secondary) !important;
}
