.input-group-text {
  background: linear-gradient(to left, white, transparent);
  border-left: none;
  border-radius: 0;
  padding-right: 3px;
  border-right: 1px solid white;
}

.porcentage {
  background: var(--my-secondary);
  font-weight: 500;
  color: white;
  padding: 5px;
  font-size: 15px;
  font-weight: 700;
  font-family: 'Asap Condensed';
  border: none;
  padding-top: 9px !important;
}

.nameItem {
  font-size: 1em;
  padding-top: 5px;
  margin-right: 1em;
  font-family: 'Asap Condensed';
  font-weight: 500;
  width: 250px;
}

.nameItemMini {
  font-size: 1em;
  padding: 0px;
  margin: 0em;
  font-family: 'Asap Condensed';
  font-weight: 100;
  width: 250px;
}

.name-item-mini {
  font-size: 1em;
  margin: 0;
  margin-right: 1em;
  font-family: 'Asap Condensed';
  font-weight: 600;
  color: black;
  border-top: 1px solid #0000000f;
  width: 100%;
  padding-top: 10px;
}

.box-mini {
  height: 20px !important;
  border: none;
  font-size: 11px;
  font-weight: 500;
}

.box-mini-currency {
  height: 20px !important;
  width: 80px !important;
  border: none !important;
  font-size: 12px;
}

.col-name {
  margin-right: 1em;
  color: var(--my-secondary);
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 10px;
  font-family: 'Asap Condensed';
}

.box-mini-blue {
  font-size: 10px;
  margin: 0;
  padding: 0 !important;
  width: 30px !important;
  border-radius: 0 7px 7px 0 !important;
  min-width: 0px !important;
  padding-top: 3px !important;
}

.alert-dados a {
  color: var(--my-secondary);
  font-size: 15px;
}

.alert-dados a:hover {
  text-decoration: underline;
}

.inputCurrency {
  max-width: 120px;
  border-left: none !important;
}

.link-click {
  margin-left: 5px;
  font-size: 11px;
  font-family: 'Asap Condensed';
  text-transform: uppercase;
  font-weight: 900;
}

.total-row {
  /* border-top: 1px solid #181819; */
}

.total-value {
  margin-top: 10px;
}

.total-prefix {
  margin-right: 5px;
  font-weight: 600;
  font-size: 1.2em;
}

.total-sufix {
  margin-right: 5px;
  font-weight: 600;
  font-size: 1.2em;
}

.ativoTotal {
  width: calc(100% - 100px);
  height: 60px;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  z-index: 99;
  padding-top: 5px;
  margin-left: 100px;
  bottom: 0;
  color: #403d3d;
  padding-left: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: -2px -15px 12px 0px #535ad040;
}

.total-bloc {
  background: none;
  font-family: 'Asap Condensed';
}

.total-date {
  font-weight: 900;
  color: var(--my-secondary);
}

.total-secondLine {
  position: relative;
  top: 13px;
}

.total-secondLine > span {
  font-weight: 600;
  margin-right: 5px;
  font-size: 1.3em;
}

.separator {
  margin-top: 13px;
  font-size: 1.5em;
}

.total-txt {
  font-size: 1.5em;
  font-weight: 800;
  font-family: 'Asap Condensed';
  padding-top: 6px;
  color: #0d0d0d;
}

.percent-sufix {
  min-width: 41px;
  text-align: center;
  display: inline-block;
  padding-top: 9px;
  border-radius: 0 10px 10px 0;
}

.charts {
  margin-left: 2em;
}

.chart-date {
  font-family: 'Asap Condensed';
  font-weight: 800;
  color: #525ad0;
}

.chart-size {
  min-width: 350px;
  height: 350px;
}

.chart-name {
  margin-right: 1em;
  color: var(--my-secondary);
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 10px;
  font-family: 'Asap Condensed';
}

.total-chart {
  display: inline;
  float: right;
  position: fixed;
  right: 0px;
}

.rootPanel {
  background: white;
  bottom: 192px;
  width: 430px;
  height: 220px;
  padding: 10px 15px;
  box-shadow: -8px -4px 10px 3px #535ad038;
  position: relative;
}

.btn-hide {
  padding: 2px 7px;
  border: none;
}

.btn-show {
  padding: 2px 7px;
  border: none;
  background: var(--my-secondary);
  color: white;
}
.show-panel {
  bottom: 75px;
  background: none;
  color: white;
  position: relative;
}

.total-secondLine-fundos {
  font-size: 1.5em;
  font-weight: 800;
  font-family: 'Asap Condensed';
  margin-top: 7px;
}
